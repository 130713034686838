<script>
import BenefitDetail from "./BenefitDetail.vue";

export default {
  components: {
    BenefitDetail
  },
  props: {
    benefits: {
      type: Object,
      required: true
    },
    currency: {
      type: Object,
      required: false,
      default: () => {}
    },
    country: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    currentCountrySpecificBenefit() {
      if (!this.country) {
        return false
      }
      return this.countrySpecificBenefits.find((entity_benefits) => entity_benefits.country === this.country)
    },
    genericBenefits() {
      return this.benefits.collective_benefits
    },
    countrySpecificBenefits() {
      return this.benefits.entity_specific_benefits
    }
  }
};
</script>

<template>
  <section>
    <div class="col-sm-12 formula-container">
      <div v-for="(benefit) in genericBenefits" :key="benefit.title">
        <h4>{{benefit.title}}</h4>
        <benefit-detail
          :benefit="benefit"
          :currency="currency"
        />
      </div>
    </div>

    <div v-if="currentCountrySpecificBenefit" class='col-sm-12 formula-container'>
      <h4>Benefits for {{country}}</h4>
      <benefit-detail
          :benefit="currentCountrySpecificBenefit"
          :editable="false"
          :currency="currency"
        />
    </div>
  </section>
</template>
