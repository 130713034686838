<script>
import SalaryCalculatorSelect from "./select.vue";
import { formatAmount, formatAmountNotRounded } from "./utils";

export default {
  components: {
    SalaryCalculatorSelect
  },
  props: {
    multipliers: {
      type: Array,
      required: true
    },
    medianSalary: {
      type: Number,
      required: true
    },
    roleGrade: {
      type: Number,
      required: true
    },
    gradeMultipliers: {
      type: Array,
      required: true
    },
    useBasePercent: {
      type: Boolean,
      required: true
    },
    vestingPeriod: {
      type: Number,
      required: true
    },
    currency: {
      type: Object,
      required: true,
    },
    exchangeUpdatedAt: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      hireOrRefresh: "New Hire",
    }
  },
  computed: {
    multiplierOptions() {
      return this.multipliers.map(multiplier => multiplier.name)
    },
    minimumDollarGrant() {
      return this.formatAmount(this.calculateDollarGrant() * 0.8)
    },
    medianDollarGrant() {
      return this.formatAmount(this.calculateDollarGrant())
    },
    maximumDollarGrant() {
      return this.formatAmount(this.calculateDollarGrant() * 1.2)
    },
    localMinimumGrant() {
      if(this.currency && this.currency.rate) { 
        return this.formatAmount(
          this.calculateDollarGrant(this.currency.rate) * 0.8,
          this.currency.code
        );
      }
      return null;
    },
    localMedianGrant() {
      if(this.currency && this.currency.rate) { 
        return this.formatAmount(
          this.calculateDollarGrant(this.currency.rate),
          this.currency.code
        );
      }
      return null;
    },
    localMaximumGrant() {
      if(this.currency && this.currency.rate) { 
        return this.formatAmount(
          this.calculateDollarGrant(this.currency.rate) * 1.2,
          this.currency.code
        );
      }
      return null;
    },
    currentGradeMultiplier() {
      return this.gradeMultipliers.find(multiplier => multiplier.grade === this.roleGrade)
    },
    currentNewHireOrRefreshFactor() {
      if (this.hireOrRefresh === 'New Hire') {
        if (this.roleGrade >= 10) {
          return 2.5
        }
        return 2
      }
      return this.multipliers.find(multiplier => multiplier.name === this.hireOrRefresh).factor
    },
    currentGradeMultiplierFactor() {
      if (this.useBasePercent) {
        return (this.currentGradeMultiplier.base_percent / 100)
      }
      return (this.currentGradeMultiplier.ote_percent / 100)
    },
    vestingPeriodYears() {
      const years = this.vestingPeriod * 4

      return years > 1 ? `${years} years` : `${years} year` 
    }
  },
  methods: {
    formatAmount,
    formatAmountNotRounded,
    calculateDollarGrant(currencyRate) {
      return Math.round(
        (this.medianSalary *
          this.currentGradeMultiplierFactor *
          this.currentNewHireOrRefreshFactor *
          this.vestingPeriod) /
          (currencyRate || 1)
      );
    },
  }
}
</script>

<template>
  <section>
    <h4>Equity Compensation</h4>

    <div v-if="vestingPeriod !== 1.0" class='alert alert-warning'>
        It seems your selected country has a vesting schedule of <b>{{ vestingPeriodYears }}</b>. 
        
        Please read our 
        <a href="https://about.gitlab.com/handbook/stock-options/#stock-option-vesting">
          equity compensation
        </a>  
        handbook page for more details.
    </div>

    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <div class="type">
            <salary-calculator-select
              v-model="hireOrRefresh"
              :options="multiplierOptions"
              label="New Hire or Refresh"
              title-field="label"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="compensation equity-compensation-box text-center">
            <div class="equity-compensation-box-title">
              Minimum Equity Dollar Grant
            </div>
            <div class="equity-compensation-box-amount amount-minimum">
              {{ minimumDollarGrant }}
            </div>
            <a
              v-if="localMinimumGrant"
              href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates"
              >{{ localMinimumGrant }}</a
            >
          </div>
        </div>

        <div class="col-sm-4">
          <div class="compensation equity-compensation-box text-center">
            <div class="equity-compensation-box-title">
              Median Equity Dollar Grant
            </div>
            <div class="equity-compensation-box-amount amount-median">
              {{ medianDollarGrant }}
            </div>
            <a
              v-if="localMedianGrant"
              href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates"
              >{{ localMedianGrant }}</a
            >
          </div>
        </div>

        <div class="col-sm-4">
          <div class="compensation equity-compensation-box text-center">
            <div class="equity-compensation-box-title">
              Maximum Equity Dollar Grant
            </div>
            <div class="equity-compensation-box-amount amount-maximum">
              {{ maximumDollarGrant }}
            </div>
            <a
              v-if="localMaximumGrant"
              href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates"
              >{{ localMaximumGrant }}</a
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="localMinimumGrant && localMedianGrant && localMaximumGrant">
    <blockquote class='disclaimer'>
      <p>
        Conversion to local currency is for illustrative purposes only using exchange rates effective as of <b>{{ exchangeUpdatedAt }}</b>.
        Actual equity grants will be made in USD as this is the currency the stock trades in.
      </p>
    </blockquote>

      <blockquote class='disclaimer'>
        <p>
          The minimum refresh amount is for those participating in the program. Individual participation is determined by leadership utilizing various factors. For more information on our refresh program and eligibility, please refer to the <a href="https://handbook.gitlab.com/handbook/stock-options/#refresh-grants">handbook</a>.
        </p>
      </blockquote>
    </div>

    <div class="col-sm-12 formula-container">
      <h4>How did we calculate your equity compensation?</h4>
      <div class="formula">
        <div class="variable-group">
          <div class="variable benchmark">
            <span class="name">Median Salary</span>
            <span class="value">{{ formatAmount(medianSalary) }}</span>
          </div>
        </div>
        <div class="symbol multiplication">x</div>
        <div class="variable-group">
          <div class="variable level">
            <span class="name">Equity Percent of Salary</span>
            <span class="value">{{ currentGradeMultiplierFactor * 100 }}%</span>
          </div>
        </div>
        <div class="symbol multiplication">x</div>
        <div class="variable-group">
          <div class="variable level">
            <span class="name">{{hireOrRefresh}} factor</span>
            <span class="value">{{ currentNewHireOrRefreshFactor }}</span>
          </div>
        </div>
        <div class="symbol multiplication">x</div>
        <div class="variable-group">
          <div class="variable level">
            <span class="name">Vesting Schedule factor</span>
            <span class="value">{{ vestingPeriod }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
