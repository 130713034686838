<script>
import { formatAmount } from "./utils.js";

export default {
  props: {
    benefit: {
      type: Object,
      required: true
    },
    currency: {
      type: Object,
      required: false,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    benefitTotal() {
      return this.benefit.items.reduce((total, item) => {
        const itemValue = item.per_dependent === undefined ? item.amount : item.amount * item.per_dependent
        return total + itemValue
      }, 0);
    },
    localCurrencyBenefitTotal() {
      return Math.round(this.benefitTotal / this.currency.rate)
    },
    totalBenefit() {
      if (this.currency && this.currency.rate) {
        return this.formatAmount(this.localCurrencyBenefitTotal, this.currency.code)
      }

      return this.formatAmount(this.benefitTotal)
    },
  },
  methods: {
    formatAmount,
    multiplicationSymbol(index) {
      return index !== this.benefit.items.length - 1 ? '+' : '='
    }
  }
}
</script>

<template>
  <section>
    <p>
      {{benefit.description}}
      <span v-if="benefit.gitlab_link">You can read more about it <a :href="benefit.gitlab_link">here</a>.</span>
    </p>


    <div class="formula">
      <div v-for="(item, index) in benefit.items" :key="item.title" class="variable-group" >

        <div class="variable benchmark">
          <span v-if="typeof(item) == 'string'" class="benefit-title">{{item}}</span>
          <span v-else class="benefit-title">{{item.title}}</span>
          <input v-if="editable && item.amount != null"
            v-model.number="item.amount"
            class="small"
            type="number"
            min="0"
          />
          <span v-else-if="item.per_dependent >= 0">
            <span class="value">{{formatAmount(item.amount)}} * </span>
            <input v-model.number="item.per_dependent" class="small" type="number" min="0" />
          </span>
          <span v-else-if="item.amount > 0" class="value">{{formatAmount(item.amount)}}</span>
        </div>

        <div class="symbol multiplication">{{ multiplicationSymbol(index) }}</div>
      </div>

      <div v-if="benefitTotal > 0" class="variable benchmark">
        <span class="benefit-title">Total</span>
        <span class="benefit-total">{{ totalBenefit }}</span>
      </div>
      <div v-else class="variable benchmark">Priceless</div>
    </div>
  </section>
</template>
