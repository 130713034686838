import TurbolinksAdapter from 'vue-turbolinks';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import Vue from 'vue';
import SalaryCalculator from '../calculator/salary.vue'

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('comp-calc');

  if (el !== null) {
    // eslint-disable-next-line no-new
    new Vue({
      el,
      render: h => h(SalaryCalculator, {
        props: {
        }
      }),
    });
  }
});