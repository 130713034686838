export const formatAmount = function (amount, currencyCode) {
  const roundedAmount = Math.round(amount / 100) * 100
  const formattedAmount = roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (currencyCode) {
    return formattedAmount + ' ' + currencyCode;
  }

  return '$' + formattedAmount;
};


export const formatAmountNotRounded = function (amount) {
  const formattedAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedAmount;
};
