<script>
import Clipboard from 'clipboard';
import SalaryCalculatorSelect from "./select.vue";
import SalaryCalculatorBenefits from "./SalaryCalculatorBenefits.vue";
import SalaryCalculatorEquity from "./SalaryCalculatorEquity.vue";
import { formatAmount } from "./utils";

const defaultLevel = { title: 'N/A', factor: 1, type: 'ic_ttc' };

const urlParams = [{
  param: 'level',
  prop: 'currentLevel',
  findIn: 'roleLevels',
  findBy: 'title',
  default: defaultLevel
}, {
  param: 'country',
  prop: 'currentCountry',
  findIn: 'countries',
}, {
  param: 'state',
  prop: 'currentState',
  optional: true
}, {
  param: 'metro',
  prop: 'currentMetroArea',
  findIn: 'currentMetroAreas',
  array: true,
  optional: true
}, {
  param: 'role',
  prop: 'currentRole',
  findIn: 'allRoles',
  findBy: 'title'
}];

export default {
  components: {
    SalaryCalculatorSelect,
    SalaryCalculatorBenefits,
    SalaryCalculatorEquity
  },
  props: {
    initialRole: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sourceData: {},
      allRoles: [],
      countries: [],
      geoZones: [],
      statesOrProvinces: [],
      locationFactors: [],
      currentCountry: null,
      currentState: null,
      currentMetroArea: null,
      countryCurrencies: {},
      currencyExchangeUpdatedAt: null,
      currentRole: null,
      currentLevel: null,
      currentCompaRatio: { min: 0.8, max: 1.2, label: 'Compa range' },
      equityLevels:  null,
      timeFrames: [],
      valuationScenarios: [],
      benefits: [],
      levelFromUrlParam: false,
      equityMultipliers: [],
      equityVestingPeriods: [],
      stockGrantAssumption: false
    };
  },
  computed: {
    currentCountryDetails() {
      if (!this.currentCountry) {
        return null
      }

      return this.locationFactors.find(factorDetail => factorDetail.country === this.currentCountry) || null
    },
    currentStates() {
      if (!this.currentCountry) {
        return null
      }

      const statesOrProvinces = this.statesOrProvinces.find(data => data.country === this.currentCountry)
      if (statesOrProvinces) {
        return statesOrProvinces.states_or_provinces
      }

      return null
    },
    currentStateDetails() {
      if (!this.currentCountry || !this.currentState || !this.currentCountryDetails) {
        return null
      }

      return this.currentCountryDetails.states_or_provinces.find(state => state.name === this.currentState)
    },
    currentGeoZone() {
      if (this.currentState) {
        return this.geoZones.find(zone => zone.states_or_provinces.includes(this.currentState))
      }

      return this.geoZones.find(zone => zone.countries.includes(this.currentCountry))
    },
    currentMetroAreas() {
      if (!this.currentCountry || !this.currentCountryDetails) {
        return null
      }

      if (this.currentStates && this.currentStates.length && this.currentStateDetails && this.currentStateDetails.metro_areas) {
        const areas = this.currentStateDetails.metro_areas.map(area => area.name)
        areas.push('Not Applicable')
        return areas
      }

      if (this.currentCountryDetails.metro_areas) {
        const areas = this.currentCountryDetails.metro_areas.map(area => area.name)
         areas.push('Not Applicable')
        return areas
      }

      return null
    },
    useDirectorFactor() {
      return (this.currentLevel.grade >= 10 && this.currentDirectorFactor > this.currentLocationFactor)
    },
    currentDirectorFactor() {
      if (this.currentCountry === 'United States') {
        return 0.800
      }
      return 0.700
    },
    options () {
      if (this.currentLevel && this.currentLevel.grade && this.equityLevels) {

        return this.equityLevels.find((level) => level.grade === this.currentLevel.grade).options;

      }
      return null;
    },
    showRoleSelector () {
      return !this.initialRole;
    },
    renderCalculator () {
      return this.currentRole && Object.keys(this.sourceData).length !== 0;
    },
    currentLevelFactor () {
      return this.currentLevel ? this.currentLevel.factor : null;
    },
    currentLevelTTCType  () {
      return (this.currentLevel && this.currentLevel.type) ? this.currentLevel.type : null;
    },
    currentTTCValues () {
      return this.currentRole[this.currentLevelTTCType]
    },
    currentBasePay () {
      return this.currentTTCValues.compensation;
    },
    currentTTCPercentage () {
      return this.currentTTCValues.percentage_variable || 0
    },
    currentLocationFactorDetails() {
      if (!this.currentCountry) {
        return null
      }

      if (this.currentState) {
        // 3 possibilities:
        // uses a metro area
        // has a state level factor (for ex Hawaii)
        // used the geozone factor
        if (this.currentMetroArea && this.currentMetroArea !== 'Not Applicable') {
          return `${this.currentMetroArea}, ${this.currentStateDetails.name}, ${this.currentCountry}`
        }

        if (this.currentStateDetails && this.currentStateDetails.factor) {
          return `${this.currentStateDetails.name}, ${this.currentCountry}`
        }

        return `${this.currentGeoZone.title}, ${this.currentCountry}`
      }

      if (this.currentMetroArea && this.currentMetroArea !== 'Not Applicable') {
        return `${this.currentMetroArea}, ${this.currentCountry}`
      }

      if (this.currentCountryDetails && this.currentCountryDetails.factor) {
        return `All, ${this.currentCountry}`
      }

      if (this.currentGeoZone) {
        return `${this.currentGeoZone.title}`
      }

      return null
    },
    currentLocationFactor () {
      if (!this.currentCountry) {
        return null
      }

      if (this.currentState) {
        // 3 possibilities:
        // uses a metro area
        // has a state level factor (for ex Hawaii)
        // used the geozone factor
        if (this.currentMetroArea && this.currentMetroArea !== 'Not Applicable') {
          return this.currentStateDetails.metro_areas.find(area => area.name === this.currentMetroArea).factor / 100.00
        }

        if (this.currentStateDetails && this.currentStateDetails.factor) {
          return (this.currentStateDetails.factor * 0.01).toFixed(2)
        }

        return this.currentGeoZone.factor
      }

      if (this.currentMetroArea && this.currentMetroArea !== 'Not Applicable') {
        return this.currentCountryDetails.metro_areas.find(area => area.name === this.currentMetroArea).factor / 100.00
      }

      if (this.currentCountryDetails && this.currentCountryDetails.factor) {
        return (this.currentCountryDetails.factor * 0.01).toFixed(2)
      }

      if (this.currentGeoZone) {
        return this.currentGeoZone.factor
      }

      return null
    },
    roleLevels () {
      if (!this.currentRole) {
        return null;
      }

      return this.sourceData.roleLevels[this.currentRole.levels] || this.currentRole.levels;
    },
    contractType () {
      const fallback = () => {
        return this.sourceData.contractTypes.find(contractType => contractType.country === '*')
      }

      if (!this.currentCountry) {
        return null;
      }

      if (this.currentCountry === 'United States') {
        return this.sourceData.contractTypes.find(contractType => contractType.country === 'USA - Inc')
      }

      return this.sourceData.contractTypes.find(contractType => contractType.country === this.currentCountry) || fallback()
    },
    isSalesRole() {
      return this.currentRole && this.currentRole.sales_role
    },
    hasNoVariablePay() {
      return this.currentTTCPercentage === 0
    },
    calculateCompensation () {
      return this.currentLevel && this.currentLocationFactor && !this.isSalesRole
    },
    medianPayForEquityFormula() {
      if (this.hasNoVariablePay) {
        return this.calculateBasePay(1.0)
      }
      return this.calculateSalary(1.0)
    },
    minimumBaseCompensation() {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateBasePay(this.currentCompaRatio.min))
    },
    localMinimumBaseCompensation() {
      if (!this.calculateCompensation) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateBasePay(this.currentCompaRatio.min, currency.rate), currency.code)
    },
    medianBaseCompensation() {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateBasePay(1.0))
    },
    localMedianBaseCompensation() {
      if (!this.calculateCompensation) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateBasePay(1.0, currency.rate), currency.code)
    },
    maximumBaseCompensation() {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateBasePay(this.currentCompaRatio.max))
    },
    localMaximumBaseCompensation() {
      if (!this.calculateCompensation) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateBasePay(this.currentCompaRatio.max, currency.rate), currency.code)
    },
    minimumCompensation () {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateSalary(this.currentCompaRatio.min))
    },
    medianCompensation () {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateSalary(1.0))
    },
    maximumCompensation () {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateSalary(this.currentCompaRatio.max))
    },
    minimumLocalCurrency () {
      if (!this.calculateCompensation) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateSalary(this.currentCompaRatio.min, currency.rate), currency.code)
    },
    medianLocalCurrency () {
      if (!this.calculateCompensation) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateSalary(1.0, currency.rate), currency.code)
    },
    maximumLocalCurrency () {
      if (!this.calculateCompensation) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateSalary(this.currentCompaRatio.max, currency.rate), currency.code)
    },
    minimumVariablePay () {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateVariablePay(this.currentCompaRatio.min))
    },
    medianVariablePay () {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateVariablePay(1.0))
    },
    maximumVariablePay () {
      if (!this.calculateCompensation) {
        return '--';
      }

      return this.formatAmount(this.calculateVariablePay(this.currentCompaRatio.max))
    },
    localCurrencyMinimumVariablePay () {
      if (!this.calculateCompensation || this.hasNoVariablePay) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateVariablePay(this.currentCompaRatio.min, currency.rate), currency.code)
    },
    localCurrencyMedianVariablePay () {
      if (!this.calculateCompensation || this.hasNoVariablePay) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateVariablePay(1.0, currency.rate), currency.code)
    },
    localCurrencyMaximumVariablePay () {
      if (!this.calculateCompensation || this.hasNoVariablePay) {
        return null;
      }

      const currency = this.countryCurrencies[this.currentCountry];

      if (!currency) {
        return null;
      }

      return this.formatAmount(this.calculateVariablePay(this.currentCompaRatio.max, currency.rate), currency.code)
    },
    blockedCountry () {
      if (this.currentCountry === null) {
        return false
      }
      return this.sourceData.countryNoHire.includes(this.currentCountry)
    },
    currentlyHiringCountry() {
      if (this.currentCountry === null) {
        return false
      }

      return this.sourceData.countriesWithPEOOrEntities.includes(this.currentCountry)
    },
    link () {
      const url = new URL(window.location.href);

      urlParams.forEach((param) => {
        const value = this[param.prop];
        if (value) {
          url.searchParams.set(param.param, value[param.findBy] || value);
        }
        else {
          url.searchParams.delete(param.param)
        }
      });

      return url.toString();
    },
    variableInformation () {
      if (this.hasNoVariablePay) {
        return null;
      }

      if (this.currentTTCValues.from_base) {
        return `(${this.currentTTCPercentage * 100}% from base)`;
      }

      return `(${this.currentTTCPercentage * 100}% from TTC)`;
    }
  },
  watch: {
    currentRole (newRole) {
      if (newRole) {
        this.setRoleLevels();
      }
    },
    currentCountry(newCountry, oldCountry) {
      if (!oldCountry) {
        return
      }
      if (this.currentStates) {
        this.currentMetroArea = null
      }
      else {
        this.currentState = null
        this.currentMetroArea = null
      }
    },
    currentState(newState, oldState) {
      if (!oldState) {
        return
      }
      this.currentMetroArea = null
    }
  },
  mounted () {
    new Clipboard('.copy-me'); // eslint-disable-line no-new
    this.getSourceData();
  },
  methods: {
    setData (data) {
      this.sourceData = data;

      // new location factors
      this.geoZones = data.geoZones
      const uniqueCountries = new Set(this.geoZones.map(geoZone => geoZone.countries).flat())
      this.countries = [...uniqueCountries].sort()
      this.locationFactors = data.locationFactors
      this.statesOrProvinces = data.statesOrProvinces

      // country -> currency mapping
      data.currencyExchangeRates.currency_rates.forEach((currency) => {
        currency.countries.forEach((country) => {
          this.countryCurrencies[country]={
            code: currency.currency_code,
            rate: currency.rate_to_usd
          };
        });
      });

      this.currencyExchangeUpdatedAt = data.currencyExchangeRates.updated_at

      this.allRoles = data.roles

      this.currentRole = data.roles.find((role) => role.title === this.initialRole);

      this.equityLevels = data.equityCalculator && data.equityCalculator.equity_grades || [];
      this.timeFrames = data.equityCalculator && data.equityCalculator.time_frames || [];
      this.timeFrames.forEach(timeFrame => {
        if (typeof(timeFrame.commonStockPrice) == 'number') {
          timeFrame.label = `${timeFrame.label} ($${timeFrame.commonStockPrice.toFixed(2)})`;
        } else { // Stock price is 'TBD'
          timeFrame.label = `${timeFrame.label}`
        }
      })
      this.valuationScenarios = data.equityCalculator && data.equityCalculator.valuation_scenarios || [];
      this.equityMultipliers = data.equityCalculator && data.equityCalculator.multipliers || [];
      this.stockGrantAssumption = data.equityCalculator && data.equityCalculator.stockGrantAssumption || 0
      // Let watchers run first
      this.$nextTick(() => {
        this.setParamsFromUrl(window.location.href);
      });

      this.benefits = data.benefitsCalculator || []
      this.equityVestingPeriods = data.equityCalculator.vesting_periods || []
    },
    getSourceData () {
      fetch('/calculator/data.json')
        .then(response => response.json())
        .then(data => this.setData(data));
    },
    setRoleLevels () {
      if (this.roleLevels) {
        if (this.levelFromUrlParam) {
          this.currentLevel = this.roleLevels.find(level => level.title === this.levelFromUrlParam)
          this.levelFromUrlParam = false
        }
        else {
          this.currentLevel = this.roleLevels.find(level => level.is_default)
        }

      } else {
        this.currentLevel = defaultLevel;
      }
    },
    setParamsFromUrl (currentUrl) {
      const currentParams = (new URL(currentUrl)).searchParams;

      urlParams.forEach((param) => {
        const urlParam = currentParams.get(param.param);
        if (!urlParam) {
          return;
        }

        if (param.prop === 'currentLevel') {
          this.levelFromUrlParam = urlParam
        }

        let foundParam = null
        if (param.findBy) {
          foundParam = (this[param.findIn] || []).find(item => item[param.findBy] === urlParam) || param.default
        }
        else {
          foundParam = urlParam
        }

        if (!foundParam) {
          return;
        }

        if (param.key) {
          foundParam = foundParam[param.key];
        }

        this[param.prop] = foundParam;
      });
    },
    formatAmount,
    formatCompaRatio (compaRatio) {
      return `${compaRatio.min  } to ${  compaRatio.max}`;
    },
    calculateBasePay (compaRatio, currencyRate) {
      if (!this.calculateCompensation) {
        return null;
      }

      const fullSalary = this.calculateSalary(compaRatio, currencyRate)
      if (this.currentTTCValues.from_base) {
        return Math.round(fullSalary/ (1 + this.currentTTCPercentage));
      }

        return Math.round((1 - this.currentTTCPercentage) * fullSalary);

    },
    calculateSalary (compaRatio, currencyRate) {
      if (!this.calculateCompensation) {
        return null;
      }

      let multiplier = this.currentLocationFactor
      if (this.useDirectorFactor) {
        multiplier = this.currentDirectorFactor
      }

      return Math.round(
        this.currentBasePay *
        multiplier *
        this.currentLevelFactor *
        compaRatio /
        (currencyRate || 1)
      );
    },
    calculateVariablePay (compaRatio, currencyRate) {
      if (!this.calculateCompensation) {
        return null;
      }

      if (this.currentTTCValues.from_base) {
        const basePay = this.calculateBasePay(compaRatio, currencyRate);
        return Math.round(basePay * this.currentTTCPercentage);
      }

      const fullSalary = this.calculateSalary(compaRatio, currencyRate);
      return Math.round(this.currentTTCPercentage * fullSalary);
    },
    
    // Find Equity Vesting Periods located in `data/equity_calculator.yml` under the `vesting_periods` key
    equityVestingPeriod () {
      if(!this.currentCountry || !this.equityVestingPeriods) {
        return null
      }

      const value = this.equityVestingPeriods[this.currentCountry] || 1.0
      return value
    }
  }
}
</script>
<template>
  <div class='salary-container row'>
    <div class="card w-100 mb-4">
      <div class="card-header" id="salary-heading">
        <button class="btn" type="button" data-toggle="collapse" data-target="#salary-collapse" aria-expanded="true" aria-controls="salary-collapse">
          <h4 class='mb-0'>Salary & equity calculator</h4>
        </button>
      </div>

      <div id="salary-collapse" class="collapse show" aria-labelledby="salary-heading">
        <div class="card-body">
          <div class='col-sm-12'>
            <h4>Select a role</h4>
            <div class='row role'>
              <div v-if="showRoleSelector" class="col-sm-6">
              <salary-calculator-select
                v-model="currentRole"
                :searchable="true"
                :options="allRoles"
                label="Role"
                title-field="title"
              />
            </div>

            <div v-if='renderCalculator' class="col-sm-6">
              <div v-if="roleLevels" class="level">
                <salary-calculator-select
                  v-model="currentLevel"
                  :options="roleLevels"
                  label="Level"
                  title-field="title"
                />
              </div>
              <div v-else class="dropdown level">
                <button class="dropdown-menu-toggle btn" data-toggle="dropdown" disabled type="button">
                  <span class="label">Level</span>
                  <span class="title" data-selected="1.0">N/A</span>
                  <span class="subtitle">1.0</span>
                </button>
              </div>
            </div>
          </div>
          </div>

          <div v-if='isSalesRole' class='row'>
            <div class='col-sm-6'>
              <div class="compensation base-compensation-box text-center">
                <div class="base-compensation-box-title">Annual Base Pay</div>
                <div>The pay range for quota carrying roles are not yet public in our compensation calculator.</div>
              </div>
            </div>

            <div class='col-sm-6'>
              <div class="compensation base-compensation-box text-center">
                <div class="base-compensation-box-title">Compensation Base/Variable Split</div>
                <div>The typical split for this role is {{currentRole.base_percentage}}% base pay and {{currentRole.commission_percentage}}% commission.</div>
              </div>
            </div>
          </div>

          <div v-if="renderCalculator && !isSalesRole" class='col-sm-12'>
            <div class="row">
              <div class="col-sm-4">
                <div class="country">
                  <salary-calculator-select
                    v-model="currentCountry"
                    :searchable="true"
                    :options="countries"
                    label="Country"
                    title-field="label"
                  />
                </div>
              </div>

              <div v-if="currentStates && currentStates.length" class="col-sm-4">
                <div class='states'>
                  <salary-calculator-select
                    v-model="currentState"
                    :searchable="true"
                    :options="currentStates"
                    label="State or Province"
                    title-field="label"
                  />
                </div>
              </div>

              <div v-if="currentMetroAreas && currentMetroAreas.length" class="col-sm-4">
                <div class='metroArea'>
                  <salary-calculator-select
                    v-model="currentMetroArea"
                    :searchable="true"
                    :options="currentMetroAreas"
                    label="Metro Area"
                    title-field="label"
                  />
                </div>
              </div>
            </div>

            <h4>Cash Compensation</h4>

            <div v-if='hasNoVariablePay' class='row'>
              <div class="col-sm-4">
                <div class="compensation base-compensation-box text-center">
                  <div class="base-compensation-box-title">Minimum Annual Base Pay</div>
                  <div class="base-compensation-box-amount amount-minimum">{{ minimumBaseCompensation }}</div>
                  <div v-if="localMinimumBaseCompensation" class="converted-local-currency-minimum">
                    <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">
                      {{ localMinimumBaseCompensation }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="compensation base-compensation-box text-center">
                  <div class="base-compensation-box-title">Median Annual Base Pay</div>
                  <div class="base-compensation-box-amount amount-median">{{ medianBaseCompensation }}</div>
                  <div v-if="localMedianBaseCompensation" class="converted-local-currency-median">
                    <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">
                      {{ localMedianBaseCompensation }}
                    </a>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="compensation base-compensation-box text-center">
                  <div class="base-compensation-box-title">Maximum Annual Base Pay</div>
                  <div class="base-compensation-box-amount amount-maximum">{{ maximumBaseCompensation }}</div>
                  <div v-if="localMaximumBaseCompensation" class="converted-local-currency-maximum">
                    <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">
                      {{ localMaximumBaseCompensation }}
                    </a>
                  </div>
                </div>
              </div>
              <div class='col-sm-12'>
                There is no variable pay component for this role.
              </div>
            </div>

            <div v-else class='row'>
              <div class="col-sm-4">
                <div class="compensation base-compensation-box minimum-box text-center">
                  <div class="base-compensation-box-title">Minimum Annual Pay</div>
                  <div>
                    <b>Base Pay</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ minimumBaseCompensation }}</span>
                    <span v-if='localMinimumBaseCompensation' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ localMinimumBaseCompensation }}</a>
                    </span>
                  </div>

                  <div>
                    <b>Variable Pay {{ variableInformation }}</b>
                    <br />
                    <span class='variable-compensation-box-amount'>{{ minimumVariablePay }}</span>
                    <span v-if='localCurrencyMinimumVariablePay' class='converted-local-currency-base-range'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ localCurrencyMinimumVariablePay }}</a>
                    </span>
                  </div>

                  <div>
                    <b>Total Target Cash</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ minimumCompensation }}</span>
                    <span v-if='minimumLocalCurrency' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ minimumLocalCurrency }}</a>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="compensation base-compensation-box median-box text-center">
                  <div class="base-compensation-box-title">Median Annual Pay</div>
                  <div>
                    <b>Base Pay</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ medianBaseCompensation }}</span>
                    <span v-if='localMedianBaseCompensation' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ localMedianBaseCompensation }}</a>
                    </span>
                  </div>

                  <div>
                    <b>Variable Pay {{ variableInformation }}</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ medianVariablePay }}</span>
                    <span v-if='localCurrencyMedianVariablePay' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ localCurrencyMedianVariablePay }}</a>
                    </span>
                  </div>

                  <div>
                    <b>Total Target Cash</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ medianCompensation }}</span>
                    <span v-if='medianLocalCurrency' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ medianLocalCurrency }}</a>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="compensation base-compensation-box maximum-box text-center">
                  <div class="base-compensation-box-title">Maximum Annual Pay</div>
                  <div>
                    <b>Base Pay</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ maximumBaseCompensation }}</span>
                    <span v-if='localMaximumBaseCompensation' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ localMaximumBaseCompensation }}</a>
                    </span>
                  </div>

                  <div>
                    <b>Variable Pay {{ variableInformation }}</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ maximumVariablePay }}</span>
                    <span v-if='localCurrencyMaximumVariablePay' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ localCurrencyMaximumVariablePay }}</a>
                    </span>
                  </div>

                  <div>
                    <b>Total Target Cash</b>
                    <br />
                    <span class='base-compensation-box-amount'>{{ maximumCompensation }}</span>
                    <span v-if='maximumLocalCurrency' class='converted-local-currency-base'>
                      <a href="https://about.gitlab.com/handbook/total-rewards/compensation/#exchange-rates">{{ maximumLocalCurrency }}</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="currentCountry && (blockedCountry || !currentlyHiringCountry)" class="col-sm-12 js-country-no-hire" >
              <div class="alert alert-warning">
                Unfortunately GitLab is not hiring in your selected country at
                this time. Please read our
                <a href="https://about.gitlab.com/handbook/people-group/employment-solutions/#country-hiring-guidelines">country hiring guidelines </a>
                for more details.
              </div>
            </div>

            <div v-if="!isSalesRole && currentLocationFactor" class="col-sm-12 formula-container">
              <h4>How did we calculate your cash compensation?</h4>
              <div class="formula">
                <div class="variable-group">
                  <div class="variable benchmark">
                    <span class="name">SF benchmark</span>
                    <span class="value">{{ formatAmount(currentBasePay) }}</span>
                  </div>
                </div>
                <div class="symbol multiplication">x</div>
                <div v-if="useDirectorFactor" class="variable-group">
                  <div class="variable locationFactor">
                    <span class="name">Director Factor</span>
                    <span class="value">{{ currentDirectorFactor }}</span>
                  </div>
                </div>
                <div v-else class="variable-group">
                  <div class="variable locationFactor">
                    <span class="name">Location Factor</span>
                    <span class="value">{{ currentLocationFactor }}</span>
                  </div>
                </div>
                <div class="symbol multiplication">x</div>
                <div class="variable-group">
                  <div class="variable level">
                    <span class="name">Level</span>
                    <span class="value">{{ currentLevelFactor }}</span>
                  </div>
                </div>

                <div v-if="useDirectorFactor">
                  <em>
                    Since your level in the role is director or up, we did not use the
                    location factor ({{currentLocationFactor}}) but the director factor ({{currentDirectorFactor}}).
                  </em>
                </div>

                <div v-else>
                  <em>We used the location factor for {{currentLocationFactorDetails}}.</em>
                </div>
              </div>

              <p class="explanation">
                Find out
                <a href="https://about.gitlab.com/handbook/total-rewards/compensation/compensation-calculator">how our calculator works</a>.
                <span v-if="calculateCompensation" class="contract-type">
                  Based on the information provided, we expect you will be
                  <span v-if="contractType.contractor">
                    a <b>contractor</b>
                  </span>
                  <span v-else>
                    an <b>employee</b>
                  </span>
                  of
                  <a href="https://about.gitlab.com/handbook/total-rewards/benefits/general-and-entity-benefits/#entity-benefits">{{ contractType.greenhouse_entity }}</a>.
                </span>
              </p>
            </div>
          </div>

          <salary-calculator-equity v-if="currentCountry && calculateCompensation"
            :multipliers="equityMultipliers"
            :median-salary="medianPayForEquityFormula"
            :role-grade="currentLevel.grade"
            :grade-multipliers="equityLevels"
            :use-base-percent="hasNoVariablePay"
            :stock-grant-assumption="stockGrantAssumption"
            :currency="countryCurrencies[currentCountry] ? countryCurrencies[currentCountry] : {}"
            :vesting-period="equityVestingPeriod()"
            :exchange-updated-at="currencyExchangeUpdatedAt"
          />

          <div v-if="calculateCompensation" class="col-sm-12 generate-url">
            <h4>
              Share compensation url -
              <a class="copy-me" data-clipboard-target="#compensation-link">copy</a>
            </h4>
            <input id="compensation-link" class="form-control" :value="link" readonly />
          </div>
        </div>
      </div>
    </div>

    <div class="card w-100 mb-4">
      <div class="card-header" id="equity-heading">
        <button class="btn" type="button" data-toggle="collapse" data-target="#equity-collapse" aria-expanded="true" aria-controls="equity-collapse">
          <h4 class='mb-0'>Equity potential value</h4>
        </button>
      </div>

      <div id="equity-collapse" class="collapse" aria-labelledby="equity-heading">
        <div class="card-body">
          For current information on GitLab's trading price, please login to view your holdings in <a href="https://us.etrade.com/etx/hw/v2/accountshome">E*TRADE</a>. You are also able to view the number of unvested shares of GitLab stock that will continue to vest based on time at the company.
        </div>
      </div>
    </div>

    <div class="card w-100 mb-4">
      <div class="card-header" id="benefits-heading">
        <button class="btn" type="button" data-toggle="collapse" data-target="#benefits-collapse" aria-expanded="true" aria-controls="benefits-collapse">
          <h4 class='mb-0'>Benefits and remote work calculator</h4>
        </button>
      </div>

      <div id="benefits-collapse" class="collapse" aria-labelledby="benefits-heading">
        <div class="card-body">
          <salary-calculator-benefits v-if="benefits.length !== 0"
            :benefits="benefits"
            :currency="currentCountry && countryCurrencies[currentCountry]"
            :country="currentCountry"
          />
        </div>
      </div>
    </div>
  </div>
</template>
